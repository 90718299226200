<template>
  <div>
    <p>
      公司名称：北京艺得在线科技有限公司
    </p>
    <p>
      公司地址：北京市大兴区亦庄经济开发区荣京东街3号荣京丽都A座415室
    </p>
    <p>
      邮编：100176

    </p>
    <p>
      客服邮箱：info@easyebid.com

    </p>
    <p>
      客服电话：400-616-9988
    </p>
    <p>
      工作时间：周一至周五上午9:00-12:00，下午2:00-6:00（法定节假日除外）
    </p>
  </div>
</template>
<script>
export default {
  name: 'contactUs'
}
</script>
<style lang="less" scoped>
div {
  text-align: left;
  padding: 20px 600px;
  p {
    font-size: 15px;
  }
}
</style>
